.alert-danger{
  li {
    background-color: transparent;
  }
}

// set modal width to 70% width;
@media (min-width: 992px) {

  .modal-lg {
    max-width: 70% !important;
  }

}

@media screen and (max-width: 1024px) {

  .modal-lg {
    max-width: 90% !important;
  }

}

//position modal below navbar
@media (min-width: 768px) {
  .modal-dialog {
    width: auto;
    margin: 90px auto !important;
  }
}

//table
.ui-widget-content {
  border: 0 !important;
}

.ui-datatable table {
  width: 95% !important;
  margin: 0 auto !important;
}

//table head
.ui-datatable thead th, .ui-datatable tfoot td {
  text-align: left !important;
}

//table td border
.ui-datatable .ui-datatable-thead > tr > th, .ui-datatable .ui-datatable-tfoot > tr > td, .ui-datatable .ui-datatable-data > tr > td{
  border-width: 0 !important;
  padding: 0.5em 0.5em !important;
  border-bottom: 1px solid $gray-lighter !important;
}

//no border on last result
.ui-datatable .ui-datatable-data > tr:last-child td{
  border-bottom: 0 !important;
}

// pagination section
.ui-widget-header {
  border: 0 !important;
  padding-top: 20px !important;
}