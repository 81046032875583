.page-aside {
  position: absolute;
  top: 0;
  // left: 0;
  bottom: 0;
  width: $page-aside-width;
  border-right: 1px solid $border-color-base;
  background: $page-aside-bg;
  transition: visibility .1s ease,top .3s ease,left .5s ease, right .5s ease;
  overflow-y: hidden;

  .list-group-item  {
    &.active, 
    &.active:focus, 
    &.active:hover {
      z-index: 0;
    }
  }
}

.page-aside-left {
  .page-aside {
    left: 0;

    + .page-main {
      margin-left: $page-aside-width;
    }
  }
}

.page-aside-right {
  .page-aside {
    right: 0;

    + .page-main {
      margin-right: $page-aside-width;
    }

    .page-aside-inner {
      border-left: 1px solid $gray-lighter;
      border-right: none;
    }
  }
}

.page-aside-fixed {
  .page-aside {
    position: fixed;
    top: (pxToRem($site-navbar-height) + $site-menubar-height);;
    $height: (pxToRem($site-navbar-height) + $site-menubar-height);
    height: calc(100% - #{$height});
  }

  .page-aside-inner {
    overflow-y: scroll;
    height: 100%;
  }

  &.page-aside-left {
    .site-footer {
      margin-left: $page-aside-width;
    }
  }

  &.page-aside-right {
    .site-footer {
      margin-right: $page-aside-width;
    }
  }
}

// Page Aside Section
// ------------------
.page-aside-section {
  position: relative;

  &:first-child {
    padding-top: $line-height-computed;
  }

  &:last-child {
    margin-bottom: $line-height-computed;
  }

  &:after {
    content:'';
    position: relative;
    display: block;
    margin: $line-height-computed;
    border-bottom: 1px solid $gray-lighter;
  }

  &:last-child:after {
    display: none;
  }
}

// Page Aside Switch
// -----------------
.page-aside-switch {
  display: none;
  cursor: pointer;
  position: absolute;
  // left: calc(100% - 1px);
  top: calc(50% - 25px);
  background-color: white;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px;

  .wb-chevron-right {
    display: inline-block;
  }

  .wb-chevron-left {
    display: none;
  }

  .page-aside-left & {
    left: calc(100% - 1px);
    padding: 15px 8px 15px 4px;
    border-radius: 0 100px 100px 0;

    .wb-chevron-right {
      display: inline-block;
    }

    .wb-chevron-left {
      display: none;
    }
  }

  .page-aside-right & {
    left: auto;
    right: calc(100% - 1px);
    padding: 15px 4px 15px 8px;
    border-radius: 100px 0 0 100px;

    .wb-chevron-right {
      display: none;
    }

    .wb-chevron-left {
      display: inline-block;
    }
  }
}


// Page Aside Title
// ----------------
.page-aside-title {
  padding: 10px $page-aside-padding-horizontal;
  margin: 20px 0 10px;
  font-weight: $font-weight-bold;
  color: $gray-darker;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}


// List group
// ----------
.page-aside {
  .list-group {
    margin-bottom: $line-height-computed;

    &-item {
      padding: 13px $page-aside-padding-horizontal;
      margin-bottom: 1px;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon {
        color: $gray;
      }

      .item-right {
        float: right;
      }

      &:hover, &:focus {
        background-color: $gray-lightest;
        border: none;
        color: $brand-primary;

        > .icon {
          color: $brand-primary;
        }
      }

      &.active {
        background-color: transparent;
        color: $brand-primary;

        > .icon {
          color: $brand-primary;
        }

        &:hover, &:focus {
          background-color: $gray-lightest;
          border: none;
          color: $brand-primary;

          > .icon {
            color: $brand-primary;
          }
        }
      }
    }

    &.has-actions {
      .list-group-item {
        cursor:pointer;
        padding-top: 6px;
        padding-bottom: 6px;
        line-height: $input-height-base;

        .list-editable {
          display:none;
          position:relative;

          .input-editable-close {
            position: absolute;
            top: 50%;
            right:0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 1;
            margin: 0;
            padding: 0;
            cursor: pointer;
            background: 0 0;
            border: 0;
            outline:none;
            -webkit-appearance: none;
          }

          .form-group {
            margin: 0;
          }
        }

        .item-actions {
          display: none;
          position: absolute;
          right: 20px;
          top: 6px;

          .btn-icon {
            padding-left: 2px;
            padding-right: 2px;
            background-color: transparent;

            &:hover {
              .icon {
                color: $brand-primary;
              }
            }
          }

          .icon {
            margin: 0;
          }
        }

        &:hover {
          .item-right {
            display: none;
          }

          .item-actions {
            display: block;
            .icon {
              color: $gray-dark;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .page-aside {
    width: $page-aside-md-width;
  }

  .page-aside-left {
    .page-aside {
      + .page-main {
        margin-left: $page-aside-md-width;
      }
    }
  }

  .page-aside-right {
    .page-aside {
      + .page-main {
        margin-right: $page-aside-md-width;
      }
    }
  }

  .page-aside-fixed {
    &.page-aside-left {
      .site-footer {
        margin-left: $page-aside-md-width;
      }
    }

    &.page-aside-right {
      .site-footer {
        margin-right: $page-aside-md-width;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .page-aside {
    position: fixed;
    top: $site-navbar-height;
    width: $page-aside-width;
    // left: -$page-aside-width;
    border-color: transparent;
    z-index: $zindex-header;
    overflow-y: visible;
    height: calc(100% - #{$site-navbar-height});
    visibility: visible;

    .site-navbar-collapse-show & {
      top: (2 * $site-navbar-height);
      $height: ($site-navbar-height*2);
      height: calc(100% - #{$height});
    }

    .site-menubar-changing &,
    .site-menubar-open & {
      height: 100%;
    }

    .page-aside-inner {
      height: 100%;
      background-color: white;
      border-right: 1px solid $gray-lighter;
    }

    &.open {
      left: 0;

      .site-menubar-changing &,
      .site-menubar-open & {
        visibility: hidden;
      }

      .page-aside-switch {
        .wb-chevron-right {
          display: none;
        }

        .wb-chevron-left {
          display: inline;
        }
      }
    }
  }

  .page-aside-left {
    .page-aside {
      left: -$page-aside-width;

      + .page-main {
        margin-left: 0;
      }
    }
  }

  .page-aside-right {
    .page-aside {
      right: -$page-aside-width;

      + .page-main {
        margin-right: 0;
      }

      &.open {

        .page-aside-switch {
          .wb-chevron-right {
            display: inline;
          }

          .wb-chevron-left {
            display: none;
          }
        }
      }
    }
  }

  .page-aside-left {
    .page-aside {
      left: -$page-aside-width !important;

      &.open {
        left: 0 !important;
      }
    }

    .site-footer {
      margin-left: 0 !important;
    }
  }

  .page-aside-right {
    .page-aside {
      right: -$page-aside-width !important;

      &.open {
        left: auto;
        right: 0 !important;
      }
    }

    .site-footer {
      margin-right: 0 !important;
    }
  }

  .page-aside-switch {
    display: block;
  }
}