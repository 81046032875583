body.site-navbar-small {
  padding-top: $navbar-small-height; // remove site menubar height from calculation
}

.page {
  min-height: 100%; // remove site menubar height from calculation
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.page-aside-fixed .page-aside {
  top: $navbar-small-height;
  height: calc(100% - #{$navbar-small-height});
}

.page-title {
  font-size: 36px;
  font-weight: $font-weight-bold;
}

.page-content {
  .card {
    position: relative;
  }
  .card:hover,
  .card:focus {
    text-decoration: none;
  }

  .card-title {
    margin-bottom: 0;
  }

  .block-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
    opacity: 0.8;
  }

}

// Custom color helpers
.color-green {
  color: #7ED321;
}

a.tag {
  text-decoration: none;
  margin: 0px 10px;
}

.table a {
  text-decoration: none;
}

.alert {
  ul {
    margin-bottom: 0;
  }
}

.circle {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.location-switcher {
  margin: 12px 25px 0 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.page-splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10000;
  cursor: default;
}

body.loading {

  .page {
    opacity: 0.5;
  }

  .page-splash {
    opacity: 1;
    z-index: 1400;
  }

}

body.no-scroll {
  overflow: hidden !important;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.no-wrap {
  white-space: nowrap;
}

.notes-display {
  white-space: pre-wrap;
}

a.disabled {
  cursor: not-allowed !important;
}

.nav-item {
  cursor: pointer;
}

// padding against button text
.btn-animate-side {

  span > .icon {
    top: 55%;
    left: -5px;
  }

  &.btn-sm {
    span > .icon {
      left: -2px;
    }
  }

  &.btn-xs {
    span > .icon {
      left: 0;
    }
  }
}


.visibility-badge {
  @apply px-1.5 py-1 font-bold rounded;

  &.Live {
    @apply bg-green-200 text-green-900;
  }

  &.Draft {
    @apply bg-purple-200 text-purple-900;
  }

  &.Scheduled {
    @apply bg-yellow-200 text-yellow-900;
  }

  &.Expired, &.No.Inventory {
    @apply bg-red-200 text-red-900;
  }
}