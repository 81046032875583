/* You can add global styles to this file, and also import other style files */
@import '../../../assets/fontawesome/css/all.min.css';

@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

.ql-first_name::after {
  content: 'FN';
}

.ql-last_name::after {
  content: 'LN';
}

@font-face {
  font-family: 'Circular';
  src: url('/assets/fonts/circular/CircularStd-Bold.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Circular';
  src: url('/assets/fonts/circular/CircularStd-Book.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Circular';
  src: url('/assets/fonts/circular/CircularStd-medium.otf') format('opentype');
  font-weight: 300;
}
