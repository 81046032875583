@use '@angular/material' as mat;
@import '../var/variables';

ul.tabs {
  padding: 0;
  margin: 0;

  li.tab {
    cursor: pointer;
    list-style: none;

    a,
    a:visited {
      color: var(--gray);
      text-decoration: none;
      padding: 0;
      text-align: center;

      transition: all 100ms ease-in-out;

      &:hover {
        color: var(--dark-gray);
      }
    }

    &.active {
      a,
      a:visited {
        color: var(--asphalt);
      }
    }
  }

  &.tabs-horizontal {
    li.tab {
      display: inline-block;
      margin-right: 24px;
      padding: 8px 8px 4px;

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        border-bottom: 2px solid mat.get-color-from-palette($primary);
        a {
          font-weight: 700;
        }
      }
    }
  }

  &.tabs-vertical {
    padding: 0;
    float: left;

    li.tab {
      display: block;
      padding: 12px 8px 12px 12px;

      a {
        padding: 8px 0;
      }

      &.active {
        padding-left: 8px;
        border-left: 4px solid mat.get-color-from-palette($primary);
      }
    }
  }

  &.tabs-filters {
    label {
      padding: 0 4px;

      &:hover {
        cursor: pointer;
      }
    }

    input[type='radio'] {
      display: none;

      &:checked + label {
        color: var(--asphalt);
        border-bottom: 2px solid mat.get-color-from-palette($primary);
        font-weight: 900;
      }
    }
  }
}
