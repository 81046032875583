@mixin form-control-focus($color: $input-border-focus){
  $color-rgba: rgba(red($color), green($color), blue($color), .3) !default;
  &.focus,
  &:focus {
    border-color: $color;
    // @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .05), 0 0 6px #{$color-rgba});
    box-shadow: none;
  }
}

@mixin form-control-extend-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5){
  .form-control-label {               // add .form-control-label
    color: $text-color;
  }

  .form-control-feedback {       // add .form-control-feedback
    color: $text-color;
  }

  .form-control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    border-color: $border-color;
  }
}

@mixin form-control-defaults(){
    background-color: $input-bg;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    color: $input-color;
    font-family: $font-family-base;
    font-size: $font-size-base;
}

@mixin input-size($input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius){
  select & {
    padding-top: 0;
    padding-bottom: 0;
  }
}
