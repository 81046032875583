// Tables

@mixin table-thead-variant($state, $background, $color) {
  .table {
    .thead-#{$state} {
      th {
        color: $color;
        background-color: $background;
      }
    }
  }
}
