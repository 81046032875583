.page-password {
  background: #F3F7F9;

  form {
    width: pxToRem(375px);
    font-size: pxToRem(16px);

    .form-control {
      padding: pxToRem(22px) pxToRem(15px);
      font-size: pxToRem(16px);
    }

    .btn {
      padding: pxToRem(10px) pxToRem(15px);
      font-size: pxToRem(16px);
    }

    a {
      color: $color-white;
    }
  }
}
