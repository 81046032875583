mat-form-field.search.mat-form-field {
  font-size: 16px;

  .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field-infix {
    padding: 0.3em 0;
  }

  .mat-form-field-label {
    margin-top: -1em;
  }

  .mat-form-field-suffix {
    font-size: 0.75em;
    margin-left: 8px;

    .mat-icon {
      width: 12px;
  
      &:hover {
        cursor: pointer;
      }

      &.hidden {
        visibility: hidden;
        cursor: default;
      }
    }
  }

  &.mat-form-field-should-float {
    .mat-form-field-label {
      margin-top: -0.4em;
    }
  }
}
