.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0;
}

.mat-calendar {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 4px 4px 8px;

    .header-label {
      align-self: center;
    }
  }
}
