.form-table {
  width: 100%;
  border-collapse: collapse;

  th {
    font-weight: bold;
    text-align: left;
  }

  th,
  td {
    padding: 12px 4px 12px 4px;
  }

  tr:hover {
    cursor: pointer;
    td {
      background-color: #f9f9f9;
    }
  }
}
