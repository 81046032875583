// Name:            Media
//
// Component:       `media`
//
// Modifiers:       `media-lg`
//                  `media-sm`
//                  `media-xs`
//
// ========================================================================

// Variables
// ========================================================================

// @media-border:             $divider-color-base;
$media-meta-color:         $text-muted !default;
$media-meta-font-size:     $font-size-sm !default;
// @media-padding-vertical:   30px;


// Component: Media
// ========================================================================

.media {
  // padding: @media-padding-vertical 0;
  // margin-top: 0;
  // border-bottom: 1px solid @media-border;

  .media {
    padding-bottom: 0;
    // margin-top: 25px;
    // border-top: 1px solid @media-border;
    border-bottom: none;
  }
}

.media-object {
  width: 120px;
}

.media-body {
  overflow: auto;
}

.media-meta {
  color: $media-meta-color;
  font-size: $media-meta-font-size;
  margin-bottom: 3px;
}

// Size Modifier
// ========================================================================

// Modifier: `media-lg`
// -------------------------

.media-lg {
  .media-object {
    width: 160px;
  }
  .media {
    margin-left: -110px;
  }
}


// Modifier: `media-sm`
// -------------------------

.media-sm {
  .media-object {
    width: 80px;
  }
  .media {
    margin-left: -70px;
  }
}


// Modifier: `media-xs`
// -------------------------

.media-xs {
  .media-object {
    width: 60px;
  }
  .media {
    margin-left: -60px;
  }
}


// Media Modifier
// ========================================================================

@include media-breakpoint-up(md) {
  .media {
    &-body {
      overflow: hidden;
    }

    .media {
      margin-left: 0;
    }
  }
}

@include media-breakpoint-down(xs) {
  .media-lg .media {
    margin-left: 0;
  }

  .media {
    .media-left {
      margin-bottom: 5px;
    }

    .media {
      padding-left: 15px;
    }
  }

  .media-left {
    display: block;
  }
}
