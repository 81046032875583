// Write your custom variables here
// --------------------------------

// Navbar
$navbar-inverse-bg: #1B1D1F;
$brand-width: 180px;

// Page Aside
$page-aside-width: 350px;
$page-aside-md-width: 250px;
