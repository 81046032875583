.aux-drawer {
  z-index: inherit;

  .mat-drawer {
    overflow: hidden;
    max-width: 100%;
  }

  .mat-toolbar {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .aux-drawer-inner-container {
    overflow: auto;
    height: calc(100% - 64px);

    .form-container {
      padding: 0 16px;
    }

    @media (max-width: 599px) {
      .form-container {
        padding: 0;
      }
    }
  }
}
