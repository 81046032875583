* {
  box-sizing: border-box;
  font: 100% $_font-stack;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
}

em {
  font-style: italic;
}

a {
  color: $_blue;
  text-decoration: none;
}

::placeholder {
  color: $_medium-gray;
}
