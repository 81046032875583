.data-cards-container {
  .mat-card {
    margin-bottom: 8px;
  }

  .mat-card-header {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .mat-card-header-text {
    margin-left: 0;

    p {
      margin: 4px 0;
    }
  }

  .mat-card-actions {
    margin: 8px 4px;
    text-align: right;
  }

  .mat-stroked-button {
    min-width: 0;
    padding: 0 8px;
    margin: 4px;
  }

  .data-card-label {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 8px;
  }

  .data-card-color {
    font-size: 24px;
    position: absolute;
    right: 16px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
  }
}
