$default-width: initial;
$default-height: 40px;

button {
  @include button($default-width, $default-height);
}

button.create {
  @extend %create-button;
}

button.close {
  font-size: 20px;
  color: $_dark_gray;
}

button.secondary {
  border-color: $_light-gray;
  background-color: white;
  color: $_gray;
}

button.tertiary {
  @include button($default-width, $default-height);
  border: none;
  background-color: transparent;
  font-weight: 700;
  color: $_primary;
}

button.unstyled {
  @include button($default-width, $default-height);
  border: none;
  background-color: transparent;
  font-weight: 500;
  color: $_text-color;
  width: initial;
}

.button-group {
  display: flex;
  flex: 0 1 auto;

  button {
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.mat-button-toggle-group .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: unset;
}
