@use '@angular/material' as mat;
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix a {
  position: relative;
  top: -2px;

  &:hover {
    cursor: pointer;
  }
}

.form-array.ng-invalid.ng-touched {
  color: mat.get-color-from-palette($warn);
}

.text-hint {
  font-size: 12px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
