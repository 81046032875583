//== Site Navbar
//
//##
$brand-width: 160px;
$brand-font-family: $headings-font-family;
$site-navbar-height: remToPx($navbar-height);
$site-navbar-color: $gray;
$site-navbar-hover-color: $gray-dark;
$site-navbar-brand-bg: $gray-darkest;

$site-navbar-header-bg: $color-blue-600;
$site-navbar-header-color: $inverse;

$site-navbar-brand-color: inherit;

//== Site Menubar
//
//##
$site-menubar-bg: $gray-base;
$site-menubar-responsive-width: 260px;

$site-menubar-padding-horizontal: 15px;

$site-menubar-color: rgba($gray, (90/100));
$site-menubar-font-family: $headings-font-family;
$site-menubar-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

$site-menubar-section-title-color: $gray-dark;

$site-menubar-footer-height: 76px;
$site-menubar-footer-color: $gray-dark;
$site-menubar-footer-link-bg: rgb(33, 41, 46);
$site-menubar-footer-link-hover-bg: rgb(30, 36, 39);

$site-menubar-height: pxToRem(50px);

//== Site Menu
//
//##
$site-menu-top-hover-color: rgba($inverse, .8);
$site-menu-top-hover-bg: rgba(255, 255, 255, .02);

$site-menu-top-active-head-bg: transparent;
$site-menu-top-active-bg: darken($site-menubar-bg, 1%);
$site-menu-top-active-border-color: rgba(0, 0, 0, .04);
$site-menu-top-active-color: $inverse;

$site-menu-top-open-head-bg: transparent;

$site-menu-top-open-bg: $site-menu-top-active-bg;
$site-menu-top-open-color: $site-menu-top-active-color;
$site-menu-top-height: 46px;
$site-menu-top-font-size: 14px;

$site-menu-sub-bg: transparent;
$site-menu-sub-color: $site-menubar-color;
$site-menu-sub-hover-bg: rgba(255, 255, 255, .02);
$site-menu-sub-hover-color: $site-menu-top-hover-color;
$site-menu-sub-active-bg: rgba(0, 0, 0, .06);
$site-menu-sub-active-color: $inverse;
$site-menu-sub-open-bg: $site-menu-sub-active-bg;
$site-menu-sub-open-color: $site-menu-sub-active-color;
$site-menu-sub-height: 38px;

$site-menu-category-color: $gray-dark;

$site-menu-has-sub-border-color: rgba(0, 0, 0, .06);
$site-menu-padding-horizontal: 20px;
$site-menu-section-header-border-color: rgba(0, 0, 0, .06);
$site-menu-responsive-padding: 30px;

//== Site Menubar light
//
//##
$site-menubar-light-color: rgba($gray-dark, (90/100));
$site-menubar-light-bg: $inverse;
$site-menubar-light-shadow: $site-menubar-shadow;
$site-menubar-light-footer-link-bg: $gray-lighter;
$site-menubar-light-footer-link-hover-bg: darken($gray-lighter, 5%);

//== Site Menu light
//
//##
$site-menu-light-top-open-head-bg: transparent;
$site-menu-light-top-hover-bg: rgba($color-primary-800, (5/100));
$site-menu-light-top-open-bg: $site-menu-light-top-hover-bg;
$site-menu-light-top-active-bg: $site-menu-light-top-hover-bg;
$site-menu-light-sub-active-bg: rgba($color-primary-800, (3/100));
$site-menu-light-sub-open-bg: rgba($color-primary-800, (3/100));
$site-menu-light-has-sub-border-color: $site-menu-light-top-hover-bg;

$site-menu-light-sub-color: $site-menubar-light-color;
$site-menu-light-top-hover-color: $site-menu-light-sub-color;
$site-menu-light-top-active-color: rgba($color-primary-800, (90/100));
$site-menu-light-sub-active-color: $site-menu-light-top-active-color;
$site-menu-light-sub-open-color: $site-menu-light-top-active-color;
$site-menu-light-sub-hover-color: $site-menu-light-sub-color;

$site-menu-light-section-header-border-color: rgba(0, 0, 0, .06);

//== Site Grid Menu
//
//##
$site-gridmenu-color: $gray;
$site-gridmenu-hover-color: $inverse;
$site-gridmenu-bg: $gray-base;
$site-gridmenu-hover-bg: rgba($inverse, (2/100));

$site-gridmenu-toggle-size: $font-size-base;

//== Site Sidebar
//
//##
$site-sidebar-width: 300px;
$site-sidebar-z-index: ($zindex-navbar + 10);
$site-sidebar-nav-height: 90px;
$site-sidebar-padding-horizontal: 30px;

//== Page
//
//##
$page-bg: #f1f4f5;

$page-vertical-padding: 30px;
$page-horizontal-padding: 30px;

//== Page Header
//
//##
$page-header-bg: transparent;
$page-header-bordered-bg: $inverse;
$page-header-vertical-padding: 30px;
$page-header-bordered-vertical-padding: 20px;

//== Page Aside
//
//##
$page-aside-padding-horizontal: 30px;
$page-aside-width: 260px;
$page-aside-md-width: 220px;
$page-aside-bg: $inverse;

//== Site Footer
//
//##
$site-footer-vertical-padding: 10px;
$site-footer-bg: rgba(0, 0, 0, .02);
$site-footer-divider-color: $gray-lighter;
$site-footer-height: 44px;
$site-footer-xs-height: 66px;

//== Layout Boxed
//
//##
$layout-boxed-width: 1320px;
$layout-boxed-bg: $gray-lighter;

//== Panels
//
///##
$panel-bg: #fff;
$panel-padding-horizontal: 30px;
$panel-body-padding-vertical: 30px;
$panel-body-padding: $panel-body-padding-vertical $panel-padding-horizontal;
$panel-heading-padding-vertical: 20px;
$panel-heading-padding: $panel-heading-padding-vertical $panel-padding-horizontal;
$panel-footer-padding-vertical: 15px;
$panel-footer-padding: 0 $panel-padding-horizontal $panel-footer-padding-vertical;
$panel-border-radius: $border-radius-large;
$panel-border: $border-color-base;

//** Border color for elements within panels
$panel-inner-border: $border-color-base;
$panel-footer-bg: transparent;

$panel-default-text: $text-color;
$panel-default-border: $border-color-base;
$panel-default-heading-bg: $brand-default;

$panel-primary-text: #fff;
$panel-primary-border: $brand-primary;
$panel-primary-heading-bg: $brand-primary;

$panel-success-text: $state-success-text;
$panel-success-border: $state-success-border;
$panel-success-heading-bg: $state-success-bg;

$panel-info-text: $state-info-text;
$panel-info-border: $state-info-border;
$panel-info-heading-bg: $state-info-bg;

$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-border;
$panel-warning-heading-bg: $state-warning-bg;

$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-border;
$panel-danger-heading-bg: $state-danger-bg;


// delivery panel colors

$panel-delivery-unassigned-text: $color-red-600;
$panel-delivery-unassigned-border: $color-red-600;
$panel-delivery-unassigned-heading-bg: $color-red-600;

$panel-delivery-assigned-text: $color-orange-600;
$panel-delivery-assigned-border: $color-orange-600;
$panel-delivery-assigned-heading-bg: $color-orange-600;

$panel-delivery-picked-text: $color-brown-500;
$panel-delivery-picked-border: $color-brown-500;
$panel-delivery-picked-heading-bg: $color-brown-500;

$panel-delivery-ontheway-text: $color-cyan-700;
$panel-delivery-ontheway-border: $color-cyan-700;
$panel-delivery-ontheway-heading-bg: $color-cyan-700;

$panel-delivery-delivered-text: $color-green-600;
$panel-delivery-delivered-border: $color-green-600;
$panel-delivery-delivered-heading-bg: $color-green-600;

$panel-delivery-rejected-text: $color-pink-400;
$panel-delivery-rejected-border: $color-pink-400;
$panel-delivery-rejected-heading-bg: $color-pink-400;

$panel-delivery-pending-text: $color-purple-600;
$panel-delivery-pending-border: $color-purple-600;
$panel-delivery-pending-heading-bg: $color-purple-600;

$panel-delivery-cancelled-text: $color-grey-400;
$panel-delivery-cancelled-border: $color-grey-400;
$panel-delivery-cancelled-heading-bg: $color-grey-400;

$panel-shadow: none;
