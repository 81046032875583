@use '@angular/material' as mat;
.form-label {
  color: var(--asphalt);
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;

  &.optional::after {
    content: 'optional';
    font-size: var(--font-size-tiny);
    font-weight: normal;
    padding-left: 8px;
  }

  &.with-actions {
    a {
      float: right;
      color: var(--primary);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.form-label-inline {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }

  &.required:after {
    content: '*';
  }
}

.form-hint {
  color: var(--gray);

  .highlight {
    color: var(--primary);
    font-size: var(--font-size-tiny);
  }

  a {
    color: var(--primary);

    &:hover {
      cursor: pointer;
    }
  }
}

.form-error {
  color: mat.get-color-from-palette($warn);
}

.form-error,
.form-hint {
  display: block;
  font-size: var(--font-size-tiny);
  margin: 8px 0;
}
