@use '@angular/material' as mat;
.mat-tab-links {
  justify-content: center;
  background-color: mat.get-color-from-palette($primary, 700);

  .mat-tab-link {
    color: mat.get-contrast-color-from-palette($primary, 700);
  }

  .mat-tab-link.mat-tab-label-active {
    opacity: 1;
  }
}

