// breakpoints
$_mobile: 480px;
$_tablet: 768px;
$_desktop-sm: 1024px;
$_desktop-md: 1280px;
$_desktop-lg: 1440px;

$_opacity: 0.4;
$_gutter-s: 8px;
$_gutter-m: 24px;

$_font-stack: Avenir, sans-serif;

$_asphalt: #464646;
$_beige: #eed69b;
$_black: #000000;
$_blue-gray: #a5bace;
$_blue: #1b8fb3;
$_dark-blue: #465a6f;
$_dark-gray: #686868;
$_dark_green: #3d8504;
$_dark-red: #ca4a4a;
$_dark-smoke: rgba(0, 0, 0, 0.6);
$_error-red: #d03333;
$_gold: #f5c982;
$_gray: #8a8a8a;
$_green: #30d6b6;
$_light-gray: #d8d8d8;
$_lighter-gray: #c0c0c0;
$_lightest-gray: #ececec;
$_magenta: #f742a2;
$_medium-gray: #a8a8a8;
$_off-white: #f9f9f9;
$_orange: #f5a623;
$_purple: #9013fe;
$_red: #e74e3c;
$_shadow: rgba(14, 169, 216, 0.15);
$_slate: #465a6f;
$_steel: #dfe5ec;
$_strawberry: #f77474;
$_teal: #50e3c2;
$_white: #ffffff;
$_info: #fff7eb;

$_primary: $_blue;
$_secondary: $_medium-gray;
$_text-color: $_asphalt;

$_font-size-large: 18px;
$_font-size-base: 16px;
$_font-size-small: 14px;
$_font-size-tiny: 12px;
