%button-base {
  border-radius: 4px;
  font-family: $_font-stack;
  font-size: $_font-size-base;
  font-weight: normal;
  outline: none;
  text-align: center;
  text-shadow: 0 1px 0 0 white;
  cursor: pointer;
  white-space: nowrap;

  * {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    * {
      cursor: not-allowed;
    }
  }
}

%create-button {
  &:before {
    content: '\f067';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 8px;
  }

  &:hover {
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
}

@mixin button($width, $height) {
  @extend %button-base;
  width: $width;
  height: $height;
}
