.metric-panel {
  &.mat-expansion-panel {
    margin-bottom: 16px;
    box-shadow: none;
    border: 1px solid var(--lightest-gray);

    .metric-list {
      display: flex;
    }

    .metric-title {
      color: var(--gray);
      font-size: 12px;
    }

    .metric {
      margin-right: 32px;
      padding-right: 32px;
    }

    .metric:not(:last-child) {
      border-right: 1px solid var(--lightest-gray);
    }
  }
}
