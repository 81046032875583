@use '@angular/material' as mat;

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.pull-right {
  float:right;
}

.bottom-margin {
  margin-bottom: 32px;
}

.spacer {
  flex: 1 1 auto;
}

.scrollable-y {
  overflow-y: scroll;
}

.clickable {
  cursor: pointer;
}

.hidden {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.small-print {
  &,
  div {
    font-size: $_font-size-small;
  }
}

.tiny-print {
  &,
  a,
  span {
    font-size: $_font-size-tiny;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-error {
  color: mat.get-color-from-palette($warn);
}

.text-primary {
  color: mat.get-color-from-palette($primary);
}

.text-secondary {
  color: mat.get-color-from-palette($accent);
}
