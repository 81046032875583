.textarea-base {
  height: 80px;
  width: 100%;
  resize: vertical;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  background-color: var(--hover-background);
  padding: 8px 10px;
  font-family: var(--font-stack);
  font-size: 16px;
}
.textarea-label-base {
  font-weight: bold;
}

.textarea-character-count {
  text-align: right;
  color: var(--gray);
  font-size: 14px;

  textarea {
    margin-bottom: 8px;
  }
}

.character-limit-met {
  color: var(--red);
}
