.productImage {
  background-size:cover;
  background-repeat:no-repeat;
  background-position: center;
  margin-bottom:0;
}

.card-title.search-name{
  font-size:15px;
}

.terpene-group > div {
  padding: 0;
  margin: 0 10px 15px 0;
}