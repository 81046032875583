// Variables
// --------------------------
$wb-font-path:        "../fonts" !default;
$wb-font-file:        "web-icons" !default;
$wb-font-family:      "Web Icons" !default;
$wb-css-prefix:       wb !default;
$wb-version:          "0.2.2" !default;

$wb-var-dashboard: "\f101" !default;
$wb-var-inbox: "\f102" !default;
$wb-var-cloud: "\f103" !default;
$wb-var-bell: "\f104" !default;
$wb-var-book: "\f105" !default;
$wb-var-bookmark: "\f106" !default;
$wb-var-tag: "\f107" !default;
$wb-var-library: "\f108" !default;
$wb-var-share: "\f109" !default;
$wb-var-reply: "\f10a" !default;
$wb-var-refresh: "\f10b" !default;
$wb-var-move: "\f10c" !default;
$wb-var-chat: "\f10d" !default;
$wb-var-chat-working: "\f10e" !default;
$wb-var-chat-text: "\f10f" !default;
$wb-var-chat-group: "\f110" !default;
$wb-var-envelope: "\f111" !default;
$wb-var-envelope-open: "\f112" !default;
$wb-var-user: "\f113" !default;
$wb-var-user-circle: "\f114" !default;
$wb-var-users: "\f115" !default;
$wb-var-user-add: "\f116" !default;
$wb-var-grid-9: "\f117" !default;
$wb-var-grid-4: "\f118" !default;
$wb-var-menu: "\f119" !default;
$wb-var-layout: "\f11a" !default;
$wb-var-fullscreen: "\f11b" !default;
$wb-var-fullscreen-exit: "\f11c" !default;
$wb-var-expand: "\f11d" !default;
$wb-var-contract: "\f11e" !default;
$wb-var-arrow-expand: "\f11f" !default;
$wb-var-arrow-shrink: "\f120" !default;
$wb-var-desktop: "\f121" !default;
$wb-var-mobile: "\f122" !default;
$wb-var-signal: "\f123" !default;
$wb-var-power: "\f124" !default;
$wb-var-more-horizontal: "\f125" !default;
$wb-var-more-vertical: "\f126" !default;
$wb-var-globe: "\f127" !default;
$wb-var-map: "\f128" !default;
$wb-var-flag: "\f129" !default;
$wb-var-pie-chart: "\f12a" !default;
$wb-var-stats-bars: "\f12b" !default;
$wb-var-pluse: "\f12c" !default;
$wb-var-home: "\f12d" !default;
$wb-var-shopping-cart: "\f12e" !default;
$wb-var-payment: "\f12f" !default;
$wb-var-briefcase: "\f130" !default;
$wb-var-search: "\f131" !default;
$wb-var-zoom-in: "\f132" !default;
$wb-var-zoom-out: "\f133" !default;
$wb-var-download: "\f134" !default;
$wb-var-upload: "\f135" !default;
$wb-var-sort-asc: "\f136" !default;
$wb-var-sort-des: "\f137" !default;
$wb-var-graph-up: "\f138" !default;
$wb-var-graph-down: "\f139" !default;
$wb-var-replay: "\f13a" !default;
$wb-var-edit: "\f13b" !default;
$wb-var-pencil: "\f13c" !default;
$wb-var-rubber: "\f13d" !default;
$wb-var-crop: "\f13e" !default;
$wb-var-eye: "\f13f" !default;
$wb-var-eye-close: "\f140" !default;
$wb-var-image: "\f141" !default;
$wb-var-gallery: "\f142" !default;
$wb-var-video: "\f143" !default;
$wb-var-camera: "\f144" !default;
$wb-var-folder: "\f145" !default;
$wb-var-clipboard: "\f146" !default;
$wb-var-order: "\f147" !default;
$wb-var-file: "\f148" !default;
$wb-var-copy: "\f149" !default;
$wb-var-add-file: "\f14a" !default;
$wb-var-print: "\f14b" !default;
$wb-var-calendar: "\f14c" !default;
$wb-var-time: "\f14d" !default;
$wb-var-trash: "\f14e" !default;
$wb-var-plugin: "\f14f" !default;
$wb-var-extension: "\f150" !default;
$wb-var-memory: "\f151" !default;
$wb-var-settings: "\f152" !default;
$wb-var-scissor: "\f153" !default;
$wb-var-wrench: "\f154" !default;
$wb-var-hammer: "\f155" !default;
$wb-var-lock: "\f156" !default;
$wb-var-unlock: "\f157" !default;
$wb-var-volume-low: "\f158" !default;
$wb-var-volume-high: "\f159" !default;
$wb-var-volume-off: "\f15a" !default;
$wb-var-pause: "\f15b" !default;
$wb-var-play: "\f15c" !default;
$wb-var-stop: "\f15d" !default;
$wb-var-musical: "\f15e" !default;
$wb-var-random: "\f15f" !default;
$wb-var-reload: "\f160" !default;
$wb-var-loop: "\f161" !default;
$wb-var-text: "\f162" !default;
$wb-var-bold: "\f163" !default;
$wb-var-italic: "\f164" !default;
$wb-var-underline: "\f165" !default;
$wb-var-format-clear: "\f166" !default;
$wb-var-text-type: "\f167" !default;
$wb-var-table: "\f168" !default;
$wb-var-attach-file: "\f169" !default;
$wb-var-paperclip: "\f16a" !default;
$wb-var-link-intact: "\f16b" !default;
$wb-var-link: "\f16c" !default;
$wb-var-link-broken: "\f16d" !default;
$wb-var-indent-increase: "\f16e" !default;
$wb-var-indent-decrease: "\f16f" !default;
$wb-var-align-justify: "\f170" !default;
$wb-var-align-left: "\f171" !default;
$wb-var-align-center: "\f172" !default;
$wb-var-align-right: "\f173" !default;
$wb-var-list-numbered: "\f174" !default;
$wb-var-list-bulleted: "\f175" !default;
$wb-var-list: "\f176" !default;
$wb-var-emoticon: "\f177" !default;
$wb-var-quote-right: "\f178" !default;
$wb-var-code: "\f179" !default;
$wb-var-code-working: "\f17a" !default;
$wb-var-code-unfold: "\f17b" !default;
$wb-var-chevron-right: "\f17c" !default;
$wb-var-chevron-left: "\f17d" !default;
$wb-var-chevron-left-mini: "\f17e" !default;
$wb-var-chevron-right-mini: "\f17f" !default;
$wb-var-chevron-up: "\f180" !default;
$wb-var-chevron-down: "\f181" !default;
$wb-var-chevron-up-mini: "\f182" !default;
$wb-var-chevron-down-mini: "\f183" !default;
$wb-var-arrow-left: "\f184" !default;
$wb-var-arrow-right: "\f185" !default;
$wb-var-arrow-up: "\f186" !default;
$wb-var-arrow-down: "\f187" !default;
$wb-var-dropdown: "\f188" !default;
$wb-var-dropup: "\f189" !default;
$wb-var-dropright: "\f18a" !default;
$wb-var-dropleft: "\f18b" !default;
$wb-var-sort-vertical: "\f18c" !default;
$wb-var-triangle-left: "\f18d" !default;
$wb-var-triangle-right: "\f18e" !default;
$wb-var-triangle-down: "\f18f" !default;
$wb-var-triangle-up: "\f190" !default;
$wb-var-check-circle: "\f191" !default;
$wb-var-check: "\f192" !default;
$wb-var-check-mini: "\f193" !default;
$wb-var-close: "\f194" !default;
$wb-var-close-mini: "\f195" !default;
$wb-var-plus-circle: "\f196" !default;
$wb-var-plus: "\f197" !default;
$wb-var-minus-circle: "\f198" !default;
$wb-var-minus: "\f199" !default;
$wb-var-alert-circle: "\f19a" !default;
$wb-var-alert: "\f19b" !default;
$wb-var-help-circle: "\f19c" !default;
$wb-var-help: "\f19d" !default;
$wb-var-info-circle: "\f19e" !default;
$wb-var-info: "\f19f" !default;
$wb-var-warning: "\f1a0" !default;
$wb-var-heart: "\f1a1" !default;
$wb-var-heart-outline: "\f1a2" !default;
$wb-var-star: "\f1a3" !default;
$wb-var-star-half: "\f1a4" !default;
$wb-var-star-outline: "\f1a5" !default;
$wb-var-thumb-up: "\f1a6" !default;
$wb-var-thumb-down: "\f1a7" !default;
$wb-var-small-point: "\f1a8" !default;
$wb-var-medium-point: "\f1a9" !default;
$wb-var-large-point: "\f1aa" !default;
