@use '@angular/material' as mat;
/*
  Table notes from designs (to be confirmed):
  - All tables should be 14px (12px is too small for the default size)
    -- 12px for 'de-prioritized' content, like certain timestamps, for certain cells
  - Never spacing on <tr> elements
  - For wider tables, 16px between rows using padding (8px top plus 8px bottom = 16px)
*/


.data-table-container {
  margin: 0 auto;
  width: 100%;
  position: relative;

  mat-spinner {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .mat-table,
  .mat-paginator {
    background-color: transparent;
  }

  .mat-table {
    position: relative;

    .mat-cell:hover {
      .actions-column {
        .actions {
          display: flex;
          justify-content: center;
        }

        .actions-closed {
          display: none;
        }
      }
    }

    .mat-cell {
      padding: 8px;
      font-size: 12px;

      .cell-wrapper {
        line-height: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .primary {
          width: 100%;
        }

        .secondary {
          flex: 1;
        }

        .phone {
          text-decoration: none;
        }
      }

      .mat-button-toggle-label-content {
        line-height: 36px;
      }
    }

    .mat-header-cell {
      padding: 8px;
    }
  }

  .mat-footer-row {
    border-bottom: 0;

    .mat-footer-cell {
      padding: 8px;
    }

    .cell-wrapper {
      font-weight: bold;
    }
  }

  // Table Cell Styles
  .cdk-column-time,
  .cdk-column-sendAt {
    .cell-wrapper {
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .mat-table .cdk-column-message {
    flex: 0 0 150px;

    .cell-wrapper {
      flex-flow: column;
      align-items: flex-start;
    }

    .primary,
    .secondary {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 134px;
    }
  }

  .mat-table .cdk-column-action {
    flex: 0 0 152px;
  }

  .primary,
  .secondary {
    font-size: 12px;
  }

  .mat-cell.color {
    .mat-icon {
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
    }
  }

  .mat-cell.actions {
    justify-content: flex-end;
    white-space: nowrap;
    min-width: 80px;

    .mat-icon:hover {
      color: $_primary;
    }

    .mat-icon:hover {
      cursor: pointer;
    }
  }

  th.mat-header-cell {
    &.text-right {
      text-align: right;
    }
  }

  .expandable-row {
    width: 100%;
    padding: 16px 10px;
    display: flex;
    justify-content: center;

    .detail-cell {
      margin-right: 32px;
      padding-right: 32px;

      label {
        font-weight: bold;
      }
    }
  }
}

.cell-days {
  span {
    &:after {
      content: ', ';
      white-space: pre;
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.data-table-container,
.data-cards-container,
.empty-state-container {
  .empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
    text-align: center;

    .icon {
      color: mat.get-color-from-palette($primary);
      font-size: 64px;
      width: 64px;
      text-align: center;
      padding: 0;
      margin: 0 0 24px;
      display: block;

      &.warn {
        color: mat.get-color-from-palette($warn);
      }
    }
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
