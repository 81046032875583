header.toolbar {
  padding: 8px 0;
  margin-top: 0;

  &,
  mat-icon {
    font-size: 120%;
  }
}

.toolbar {
  display: flex;
  margin: 16px 0;

  .toolbar--left {
    display: flex;
    flex: 1;
    height: 40px;

    button {
      height: inherit;
      margin-right: 16px;
    }

    .mat-slide-toggle {
      height: inherit;
      vertical-align: bottom;
    }
  }

  .toolbar-title {
    line-height: 40px;
  }

  .toolbar--right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .mat-stroked-button {
      margin-left: 8px;
  
      .mat-button-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .mat-flat-button {
    min-width: unset;
  }

  .toolbar-icons {
    margin-right: 8px;

    .mat-stroked-button {
      min-width: 0;
      padding: 0 8px;
    }
  }

  &.mobile {
    flex-direction: column;

    .toolbar--left {
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }
}

// TODO: delete me
.analytics-placeholder {
  width: 100%;
  background: #f2f2f2;
  border: thin solid black;
  height: 250px;
}
