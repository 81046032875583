
.layout-full {
  height: 100%;

  .page {
    height: 100%;
    margin: 0 !important;
    padding: 0;
    background-color: transparent;
  }

  > .loader {
    margin-left: 0 !important;
  }
}

body.layout-full {
  padding-top: 0;
}
