.modal-content {
  .title-container:first-child {
    margin-top: 0;
  }
  .form-buttons {
    margin: 16px 0;
  }
}

mat-dialog-actions, div[mat-dialog-actions] {
  float: right;
}

.mat-dialog-actions button {
  margin: 4px;
}
