mat-checkbox {
  display: block;
}

.checkbox-wrapper {
  mat-checkbox {
    display: inline-block;
    margin: 0 24px 8px 0;
  }
}
