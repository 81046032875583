.mat-tooltip {
  font-size: 16px !important;
  white-space: pre-line;
}

.cdk-overlay-container {
  z-index: 2000;
}

.mat-dialog-container {
  position: relative;
}

.cdk-overlay-pane.side-dialog {
  .mat-dialog-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 768px;
  }
}

// prevent time picker from opening behind a material dialog
.timepicker-overlay {
  z-index: 3000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 3000 !important;
}
