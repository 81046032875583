.cdk-overlay-container {
  .notification-menu {
    max-width: 320px;

    .mat-menu-content {
      padding-top: 0;
      padding-bottom: 0;
    }

    .list-group {
      max-height: 300px;
      overflow-y: auto;

      a.list-group-item {
        display: flex;
        align-items: center;
        height: 60px;
      }
    }
  }
}
