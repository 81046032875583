.time-container {
  .table {
    margin-bottom: 0;

    .spacer, .meridian {
      vertical-align: middle;
    }

    tr td {
      border-top: 0;
      padding: 0;

      .mat-form-field-appearance-outline {

        .mat-form-field-outline {
          color: white;
        }

        .mat-form-field-flex, .mat-form-field-wrapper, .mat-form-field-infix {
          padding: 0;
          border-top: 0;
        }

        .mat-form-field-wrapper {
          border-bottom: 1px solid;
        }
      }
    }
  }
}
