ul.blocks {
  li {
    figure.overlay {
      padding-top: 70%;

      i.icon, mat-icon {
        transition: all 300ms ease-in-out;
        position: absolute;
        width: 100%;
        top: 42.5%;
        left: 0;
        font-size: 60px;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
      }

      &:hover {
        i.icon, mat-icon {
          transform: scale(1.1);
        }
      }

    }

    &:nth-of-type(1) {
      figure {
        background-color: #02A9D3;
      }
    }
    &:nth-of-type(2) {
      figure {
        background-color: #01C7D2;
      }
    }
    &:nth-of-type(3) {
      figure {
        background-color: #00D5C3;
      }
    }
    &:nth-of-type(4) {
      figure {
        background-color: #FC584E;
      }
    }
    &:nth-of-type(5) {
      figure {
        background-color: #FD8459;
      }
    }
    &:nth-of-type(6) {
      figure {
        background-color: #FDA259;
      }
    }
    &:nth-of-type(7) {
      figure {
        background-color: #FDCC5B;
      }
    }
    &:nth-of-type(8) {
      figure {
        background-color: #A58ADD;
      }
    }
    &:nth-of-type(9) {
      figure {
        background-color: #36ab7a;
      }
    }
  }
}
