
@include media-breakpoint-down(sm) {
  .site-navbar-collapsing {
    transition: padding-top .35s;
  }

  body.site-navbar-collapse-show {
    padding-top: (2 * $site-navbar-height);
  }

  .site-navbar-collapse-show {
    .slidePanel {
      &.slidePanel-right, &.slidePanel-left {
        top: (2 * $site-navbar-height);
        transition: top .35s;
        // height: calc(100% - 135px);
      }
    }
  }
}
