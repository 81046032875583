@use '@angular/material' as mat;
// TODO: break this up into other *.scss files

form {
  font-size: 16px;
}

fieldset {
  border: none;
  padding: 0;
  width: 100%;
}

.form-buttons {
  margin: 32px auto;
  width: 100%;
  text-align: center;

  .button {
    min-width: 200px;
    margin: 4px;

    &[disabled] {
      .mat-button-wrapper {
        color: $_asphalt;
      }
    }

    .mat-progress-spinner {
      margin-left: 8px;
      display: inline;
    }
  }
}

.form-component {
  width: 100%;

  .mat-form-field {
    display: initial;
  }

  &.inline {
    width: initial;
  }

  &.split-2 {
    width: 48%;
  }
  &.split-3 {
    width: calc(94% / 3);
  }
  &.split-4 {
    width: 23%;
  }

  p {
    margin: 8px 0;
  }

  .checkbox-container {
    margin-bottom: 8px;
  }
}

.form-container {
  margin: 0 auto;
  max-width: 600px;
  padding: 0 16px;

  &.flexible {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;

    .form-component:not(:empty) {
      margin: 8px auto;
      padding: 0 4px;

      // these following styles allow mat-hints to wrap and provides consistent spacing for them
      .mat-form-field-wrapper {
        padding: 0;
      }
      .mat-form-field-subscript-wrapper {
        position: relative;
      }
    }

    .form-indented {
      @extend .form-indented;
    }
  }

  .title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid mat.get-color-from-palette($accent);
    margin: 40px 0 16px;
    padding-bottom: 8px;

    a {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: mat.get-color-from-palette($primary);
      font-size: 14px;
    }

    .form-title {
      color: mat.get-color-from-palette($accent);
      font-size: 20px;
      font-weight: 500;
      margin: 0;

      span {
        font-size: 20px;
      }

      &.form-title-with-subtext {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .subtext {
          font-size: 14px;
        }
      }
    }
  }
}

.form-fieldset-split {
  .mat-form-field {
    display: inline-block;
    width: 48%;
  }
  .mat-form-field:first-child {
    margin-right: 2%;
  }
  .mat-form-field:last-child {
    margin-left: 2%;
  }
}

.form-fieldset-three {
  .mat-form-field {
    display: inline-block;
    width: 31%;
  }
  .mat-form-field:nth-child(2) {
    margin-left: 2%;
    margin-right: 2%;
  }
}

.form-image-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-left: 8px;
    line-height: 24px;
  }
}

.form-indented {
  margin-left: 32px;
}

.form-input {
  height: 40px;
  width: 100%;
  border: 1px solid mat.get-color-from-palette($accent);
  border-radius: 5px;
  padding: 0 10px;
  font-size: 16px;
  width: 100%;

  &.error {
    border-color: mat.get-color-from-palette($warn);
    color: mat.get-color-from-palette($warn);
  }
}

.form-input-select {
  .form-input {
    display: inline-block;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: 0;
    width: 70%;
  }
  .ng-select {
    display: inline-block;
    margin: 0;
    width: 30%;

    .ng-select-container {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.form-horizontal {
  display: flex;
  justify-content: space-between;
}

.form-horizontal-centered {
  @extend .form-horizontal;
  align-items: center;
}

.form-radio-group {
  .horizontal {
    .form-radio-button {
      margin-right: 16px;
    }
    .form-radio-button:last-child {
      margin-right: 0;
    }
  }

  .vertical {
    .form-radio-button {
      display: block;
      margin-bottom: 16px;
    }
  }

  .mat-radio-group {
    display: block;
    margin: 8px 0;
  }
}

validation-errors,
validation-errors .mat-error {
  font-size: 12px;
  padding-left: 8px;
  color: mat.get-color-from-palette($warn);
}

dynamic-form-content {
  width: 100%;
}

@media (max-width: 480px) {
  .form-container.form-wide {
    width: 100%;
    padding: 0 8px;
  }
}

@media (min-width: 768px) {
  .form-container.form-wide {
    width: 736px;
  }
}
