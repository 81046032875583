.transactionLog-title {
  border-bottom: 1px solid $border-color-base;
  margin-bottom: 25px;
}

.transactionLog-item {

  padding: pxToRem(15px) pxToRem(0px);
  td {
    border: 0;
  }

  p.transactionLog-item-product {

    padding: .715rem 0;
    margin: 0;
    overflow-x: hidden;
    font-size: 17px;

    &.dotted {
      &:before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
      }
    }

    &.pointer {
      cursor: pointer;
    }

    > span:first-child {
      padding-right: 0.33em;
      background: white
    }

    span + span {
      float: right;
      padding-left: 0.33em;
      background: white;
    }
  }

  &:last-child {
    margin-bottom: 30px;
  }

  .surcharge {
    .name, .item {
      font-size: 12px;
    }

    .item {
      text-align: right;
    }

    .rate {
      padding-left: 6px;
    }
  }
}

td.transactionLog-item-table-container, div.transactionLog-item-table-container {
  margin: 0;
  padding: 0;

  .collapse.in {
    border-bottom: 1px solid $border-color-base;
    padding-top: 0.572rem;
  }

  &.collapse.in {
    padding-top: 0.572rem;
  }

}

table.transactionLog-item-table {
  border-top: 1px solid #e4eaec;
  margin: 0;
  padding: 0;
}

.transactionLog-total {
  font-size: 17px;
  td {
    border: 0;
  }
}

.transactionLog-item-border-bottom {
  border-bottom: 1px solid $border-color-base;
}

.refund-date{
  border-bottom: 1px solid #62a8ea;
}

tr.transactionLog-item-tax {
  color: $color-red-500;
}

// Chartist override
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: $color-green-600 !important;
}

