.page-login {
  background: #1B1D1F;

  form {
    width: pxToRem(375px);
    font-size: pxToRem(16px);

    max-width: 95%;
    margin: 0 auto;

    .form-control {
      // padding: pxToRem(22px) pxToRem(15px); this hides the user input on IE and Firefox
      height:pxToRem(50px);
      font-size: pxToRem(16px);
    }

    .btn {
      padding: pxToRem(10px) pxToRem(15px);
      font-size: pxToRem(16px);
    }

    a {
      color: $color-white;
    }

    //  For when the End User forgets to fill in a field.
    .help-block {
      height: pxToRem(50px);
      font-size: pxToRem(16px) !important;
      color: $color-red-700 !important;
      line-height: pxToRem(50px);
      font-weight: bold;
    }
  }
}
