@mixin tag-styles-variant($color, $hover-color) {
  &[href] {
    &:hover,
    &:focus {
      background-color: $hover-color;
    }
  }

  &.tag-outline {
    color: $color;
    background-color: transparent;
    border-color: $color;
  }
}
