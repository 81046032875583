@use '@angular/material' as mat;
.snapshot {
  padding: 30px 30px 20px;
  margin-bottom: 30px;

  .mat-card-content {
    position: relative;
  }

  .mat-expansion-panel {
    box-shadow: none;

    .mat-expansion-panel-header {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-panel-header-title {
      justify-content: flex-end;
    }

    .mat-expansion-panel-header-description {
      font-size: 12px;
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: none;
  }

  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0;
  }
}

.snapshot-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 16px;

  .mat-icon {
    font-size: 20px;
    margin-right: 4px;
  }

  @media (max-width: 599px) {
    justify-content: center;
  }
}

.snapshot-body {
  position: relative;
}

.metrics-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.metrics-background {
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  right: 1px;
  z-index: 1;

  @media (max-width: 599px) {
    background-color: var(--lightest-gray);
  }
}

.metric {
  background-color: var(--white);
  margin-right: 1px;
  margin-bottom: 1px;
  min-width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 8px;

  &:not(:last-child) {
    border-right: 1px solid var(--lightest-gray);

    @media (max-width: 599px) {
      border: none;
    }
  }

  @media (max-width: 599px) {
    flex-grow: 1;
  }
}

.metric-value {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 18px;

  &.warn {
    color: mat.get-color-from-palette($warn);
  }

  &.success {
    color: #5AA454;
  }

  &.info {
    color: $_gold;
  }

  &.blue {
    color: #01579b;
  }

  &.purple {
    color: #311b92;
  }
}

.metric-title {
  color: var(--gray);
  font-size: 12px;
  max-width: 75px;
  line-height: 13px;
  text-align: center;
}

.snapshot.mat-card .mat-divider-horizontal.mat-divider-inset {
  margin-top: 16px;
  margin-bottom: 16px;
}

.list-metric {
  width: 150px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;

  &.number {
    text-align: right;
    font-size: 16px;
  }

  &.success {
    color: #5AA454;
  }

  &.info {
    color: $_gold;
  }

}

@media (max-width: 599px) {
  .list-metric {
    width: unset;

    &.number {
      width: 50%;
      display: inline-block;
    }
  }

  .mat-list-base .mat-list-item .mat-list-text .list-metric:first-child {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}
