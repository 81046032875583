.filter-drawer {
  .mat-toolbar {
    .spacer {
      flex: 1 1 auto;
    }

    .mat-stroked-button {
      min-width: 0;
      padding: 0;
    }
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 20px;
    }
  }

  .filter-field {
    display: flex;
    align-items: center;
    max-width: 250px;
    min-height: 68px;
    padding: 0 16px;
    margin: 0 0 20px;
  }
}
