query-condition-group {
  mat-form-field {
    padding: 4px;
  }

  .actions {
    text-align: center;
    padding: 24px 0 0;
  }

  .container {
    display: flex;
    flex-wrap: wrap;

    .mat-form-field {
      flex: 1;
    }
  }
}
