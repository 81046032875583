.simple-table {
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    font-weight: 700;
    color: var(--text-color);
  }

  th,
  td {
    line-height: 40px;

    &:first-child {
      padding-left: 8px;
    }
  }

  tr:nth-child(2n) {
    background-color: var(--hover-background);
  }
}
