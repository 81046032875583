.accounting {

}

.accounting-sidebar {
  padding: pxToRem(12px) pxToRem(30px);
}

.station-amount {
  font-size: pxToRem(24px);
}

.safe-amount {
  font-size: pxToRem(28px);
}
