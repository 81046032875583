@use '@angular/material' as mat;
.navbar.mat-toolbar button.nav-item span {
  margin-left: 4px;
}

.sidenav {
  width: 296px;

  .mat-nav-list:last-child {
    padding-bottom: 50px;
  }

  &.mat-drawer {
    overflow-y: auto;
  }

  fieldset {
    width: 244px;
    margin-left: 16px;
  }

  .mat-icon {
    min-width: 40px;

    &.fa-chevron-down,
    &.fa-chevron-up {
      position: absolute;
      right: 16px;
    }
  }

  .mat-toolbar {
    background: $_white;
    width: 100%;
    padding: 0 20px;
  }

  .mat-form-field {
    width: 100%;
  }

  .mat-list-item.sub-nav-item {
    padding-left: 50px;
  }

  .mat-list-item.active {
    border-left: 4px solid mat.get-color-from-palette($primary);
  }

  .mat-list-base {
    padding-top: 0;
  }

  .mat-drawer-inner-container {
    padding-bottom: 50px;
  }

  .mat-list-item.nav-item__help {
    position: fixed;
    bottom: 0;
    background: $_white;
    width: 296px;
  }
}

.outer-mat-sidenav-content {
  .nav-item + .nav-item {
    margin-left: 32px;
  }
}

.acl-menu {
  .mat-menu-item {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
  }

  .mat-menu-item[disabled] {
    color: rgba(0, 0, 0, 1);
  }

  button {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

button[mat-menu-item].active {
  background-color: mat.get-color-from-palette($primary) !important; // important is to override hover state
  color: mat.get-contrast-color-from-palette($primary, 500);
  border-radius: 0;
}

#nav-loader {
  position: fixed;
  top: calc(50% - 50px); // accounting for 100px width on nav-spinner to center
  left: calc(50% - 50px);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.location-drawer .mat-toolbar {
  background: $_white;
}
