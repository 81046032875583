.flex-grid {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
    align-items: baseline;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.topline {
    align-items: unset;
  }

  &.columned {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.mat-drawer-content.outer-mat-sidenav-content,
.mat-drawer .mat-drawer-inner-container {
  overflow: unset;
}

.mat-drawer-container.mat-sidenav-container {
  height: 100%;
}

.login.filter-drawer {
  height: 100vh;
}

#sub-navigation-tabs-container {
  .mat-tab-nav-bar {
    border-bottom: none;
  }
}

.sub-navigation-wrapper section.section-wrapper,
.sub-navigation-wrapper .aux-drawer .mat-drawer-inner-container {
  height: calc(100vh - 112px);

  @media (max-width: 599px) {
    height: calc(100vh - 104px);
  }
}

section.section-wrapper {
  height: calc(100vh - 64px);
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px;

  @media (max-width: 599px) {
    height: calc(100vh - 56px);
  }

  @media (max-width: 480px) {
    padding: 12px;
  }

  &.section-wrapper--small {
    max-width: 736px;
  }
}

.breadcrumbs ~ .sub-navigation-wrapper {
  // if breadcrumb class is sister to sub-navigation wrapper,
  // subtract the additional height from the breadcrumb to prevent unnecessary overflow on y-axis
  section.section-wrapper,
  .aux-drawer .mat-drawer-inner-container {
    height: calc(100vh - 148px) !important; // +32 pixels

    @media (max-width: 599px) {
      height: calc(100vh - 152px); // +32 pixels
    }
  }
}

.breadcrumbs ~ cl-locations-drawer {
  // if breadcrumb class is sister to mat-drawer-content,
  // subtract the additional height from the breadcrumb to prevent unnecessary overflow on y-axis
  mat-drawer-content.mat-drawer-content {
    height: calc(100vh - 96px) !important;
  }
}

.section-wrapper--small {
  max-width: 736px;
  margin: 0 auto;
  padding: 12px;
}

