
.layout-boxed {
  @include media-breakpoint-up(xl) {
    background: $layout-boxed-bg;

    &, & .site-navbar, & .site-menubar {
        max-width: $layout-boxed-width;
        margin-left: auto;
        margin-right: auto;
    }
  }

  .slidePanel {
    &-right, &-left {
      top: 0;
      // margin-top: 0;
      // height: 100%;
      z-index: ($zindex-navbar-fixed + 10);
    }
  }
}
