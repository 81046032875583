.default-table {
  position: relative;

  .progress-bar {
    position: absolute;
  }

  table {
    width: 100%;

    tbody {
      td {
        padding: 5px 0;
      }
    }

    .actions {
      text-align: right;
    }

    .attribute-wrapper {
      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: 4px;
    }
  }
}

.pointer {
  cursor: pointer;
}

.clear-filters-button {
  min-width: 0 !important;
  padding: 0 !important;
}

.filter-spacer {
  flex: 1 1 auto;
}

.filter-fields {
  padding: 0 20px;
}

.toggle-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.34375em;
}

.drawer-content-wrapper {
  min-height: calc(100vh - 100px);

  .custom-mat-toolbar {
    display: flex;
    justify-content: space-between;
    background: #f1f4f5;

    .toolbar-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .toolbar-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.btn-cancel {
  margin-left: 20px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-stepper {
  .mat-step-header {
    padding: 24px 0 !important;
  }

  .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background-color: transparent !important;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }
}

.recipient-form {
  .add-condition-column {
    button {
      margin-top: 5px;
      width: 100%;
      height: 62px;
    }
  }

  .operator-field {
    width: 50px;
    margin: 0 10px;
  }

  .logical-operator-divider {
    text-align: center;
    position: relative;
    margin: 6px 0;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 1px solid #e4eaec;
      background: #e4eaec;
      width: 100%;
      transform: translateY(-50%);
    }

    .wrapper {
      display: flex;
      justify-content: center;
      position: relative;
    }

    .operator {
      background-color: white;
      padding: 0 10px;
    }
  }
}

.campaign-recipient {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;

  .title {
    margin-bottom: 10px;
  }

  .action-column {
    padding-top: 4px;

    button[mat-stroked-button] {
      margin-right: 0.75rem;
    }

    button {
      width: 76px;
      height: 63px;
    }
  }
}

//fixing z-index for the editor
.tox-tinymce-aux {
  z-index: 13000 !important;
}

//form elements
.no-input-spaces .mat-form-field-wrapper {
  margin: 0 !important;
  padding-bottom: 0 !important;
}
