.bundle-tab-header {
  .mat-tab-header {
    padding-bottom: 24px !important;
  }
  .mat-tab-header, .mat-tab-nav-bar {
      border-bottom: 0;
    }
}

.bundle-toggle {
  .mat-slide-toggle-label {
    flex-direction: row-reverse;

    .mat-slide-toggle-content {
      padding-right: 10px;
    }
  }
}
