.page-content {
  @media screen and (max-width: 1024px) {
    padding: 20px 15px 0;
  }
}

.orders-sidebar {
  overflow: auto;
  padding-bottom: 15px;
  max-width: 95%;

  .page-aside-switch {
    transition: right 0.5s ease;
    position: fixed !important;
    right: 0;
  }

  &.open {
    .page-aside-switch {
      right: 350px;
    }
  }
}

.orders-main {
  margin-right: pxToRem(350px);
  overflow: auto;

  @media (max-width: 1199px) {
    margin-right: pxToRem(220px);
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.orders-sidebar-header {
  min-height: 70px;
  position: relative;
  display: block;
  border-bottom: 1px solid $border-color-base;
  padding: 0 pxToRem(15px);

  @media (max-width: 1024px) {
    font-size: 12px;
    padding: pxToRem(10px) pxToRem(10px);
  }
}

.orders-sidebar-consumer-container {
  .mat-expansion-panel-header {
    display: flex;
    align-items: center;
  }
}

.orders-sidebar-item,
.orders-sidebar-discount {
  cursor: pointer;
  padding: pxToRem(15px) pxToRem(20px);

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: pxToRem(15px);
  }

  border-bottom: 1px solid $border-color-base;

  p {
    margin-bottom: 0;
    font-size: 13px;
  }

  div.orders-sidebar-item-product-name {
    font-weight: bolder;
    max-width: 21em;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  div.orders-sidebar-item-product-details,
  div.orders-sidebar-discount-code {
    position: relative;

    margin: 0 pxToRem(10px);

    @media (max-width: 1024px) {
      margin: 0;
    }

    &.has-input {
      margin-right: 0;
    }

    -webkit-transition: height 0.2s ease-in-out;
    -moz-transition: height 0.2s ease-in-out;
    -o-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;

    max-width: 40em;
    padding: 0;
    overflow-x: hidden;
    line-height: pxToRem(34px);
    height: pxToRem(35px);

    &.expanded {
      > span {
        visibility: hidden;
      }

      position: relative;
      height: pxToRem(210px) !important;
      overflow: hidden;

      .form-group {
        position: absolute;
        top: 0;
        left: 0;

        label {
          background: #fff;
          padding: 0;
          margin: 0;
        }

        .btn-group {
          margin-top: pxToRem(12px);
        }
      }
    }

    &:before {
      float: left;
      width: 0;
      white-space: nowrap;
      content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
        '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ';
    }

    > span:first-child {
      padding-right: 0.33em;
      background: white;
    }

    > span + span {
      position: absolute;
      right: 0;
      top: 10px;
      padding-left: 0.33em;
      background: white;
    }

    a {
      cursor: pointer;

      span {
        padding-left: 4px;
        top: -2px;
      }
    }
  }
}

div.orders-sidebar-discount-code {
  color: $color-cyan-500;
}

.orders-sidebar-item-btn {
  padding-bottom: 15px;

  .btn {
    padding: pxToRem(15px) pxToRem(20px);
    width: 49%;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      padding: pxToRem(10px) pxToRem(10px);
    }
  }
}

.orders-sidebar-discount {
  padding: pxToRem(15px) pxToRem(30px);
  color: $color-red-500;
}

.orders-sidebar-discount .btn {
  margin: 15px 0;
  display: none;
  padding: pxToRem(15px) pxToRem(20px);
}

.total-breakdown {
  line-height: pxToRem(32px);
  padding: pxToRem(15px) pxToRem(30px) 0;
  border-top: 1px solid #e4eaec;

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: pxToRem(15px);
  }
}

.total, .payment-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: pxToRem(15px) pxToRem(30px);

  .total-title {
    text-transform: uppercase;
    color: $color-grey-800;
    font-weight: bold;
  }
}

.payment-section {
  padding-top: 0;

  .payment-section-status {
    text-transform: uppercase;
  }
}

.order-items-container {
  @media (min-width: 1200px) {
    min-height: 350px;
  }
}

.orders-actions {
  position: absolute;
  top: 50%;
  right: pxToRem(30px);
  margin: auto;
  transform: translateY(-50%);

  @media screen and (max-width: 1199px) {
    position: inherit;
    margin: pxToRem(35px) 0 0 pxToRem(55px);
  }
}

.orders-actions-checkout {
  padding: pxToRem(15px);
  border-top: 1px solid $border-color-base;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 0;
    float: left;
    margin: pxToRem(3px);

    @media (min-width: 768px) and (max-width: 1199px) {
      width: 100% !important;
    }
  }

  .order-action-discount {
    width: 78%;

    button {
      height: 53px;
    }
  }

  .order-action-delete {
    width: 18%;

    button {
      height: 53px;
    }
  }

  .order-action-save,
  .order-action-ready,
  .order-action-audit,
  .order-action-delivery {
    width: 48%;

    &.full {
      width: 98%;
    }

    button {
      height: 53px;
    }
  }

  .order-action-finish {
    width: 98%;

    button {
      height: 83px;
    }
  }

  button {
    font-size: 16px;
    width: 100%;
    padding: pxToRem(5px);
    display: block;
  }
}

// Inventory Product Modal

.inventoryProduct {
  position: relative;
  border-top: 1px solid $border-color-base;
  border-bottom: 1px solid $border-color-base;
  padding: pxToRem(15px) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inventoryProduct-image {
  max-height: 250px;
  padding: 0 !important;
  margin: 0 auto;
}

.inventoryProduct-details {
  display: block;
  font-weight: $font-weight-bold;

  @media screen and (max-width: 1199px) {
    padding-right: pxToRem(40px);
  }
}

.inventoryProduct-details a {
  width: 5%;
  display: inline-block;
  position: absolute;
  left: -30px;
}

.inventoryProduct-title {
  font-size: 16px;
  line-height: 30px;
}

.inventoryProduct-info {
  font-size: 11px;
  line-height: 23px;
}

.inventoryProduct-label {
  font-weight: $font-weight-light;
  padding: 0 2px 0 10px;
}

.inventoryProduct-qty {
  display: block;
}

.inventoryProduct-button {
  margin-top: pxToRem(45px);
  font-size: 17px;

  .btn-tag {
    padding: 18px !important;
    min-width: pxToRem(100px);
    text-align: right;
  }
}

.modal-error {
  ul {
    list-style: none;
    margin-bottom: 25px;

    li {
      padding-bottom: 15px;
      font-size: 16px;
      color: $color-red-500;
    }
  }
}

.category-header {
  border-bottom: 1px solid $color-grey-300;
}

.product-adjust-wrapper {
  input {
    text-align: right;
    font-size: pxToRem(13px) !important;
    font-weight: 500 !important;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .btn-tag {
    color: $color-grey-600;
    font-weight: 500;
    font-size: pxToRem(13px) !important;
    background: #fff !important;
    padding-left: pxToRem(10px) !important;
    padding-right: pxToRem(10px) !important;
  }

  .clear-button {
    border: 1px $color-red-500 dashed;
    color: $color-red-500 !important;

    .btn-tag {
      > span {
        display: none;
        color: $color-red-500;
        text-decoration: line-through;
      }
    }

    &:hover {
      color: $color-red-500 !important;
      border: 1px $color-red-500 dashed;

      .btn-tag {
        > span {
          display: inline;
        }
      }
    }
  }

  .edit-button {
    border: 1px $color-grey-500 dashed;
    color: $color-green-500 !important;

    .btn-tag.hover-show-tag {
      margin-right: 0;
    }

    &:hover {
      color: $color-green-500 !important;
      border: 1px $color-green-600 dashed;
    }
  }
}

.product-total-adjust-wrapper {
  max-width: 50%;
  margin-left: pxToRem(10px);

  .product-total-input-group {
    border: 1px transparent solid;
  }
}

.delivery-fee-adjust-wrapper {
  .delivery-fee-input-group {
    max-width: 50%;
    border: 1px transparent solid;
  }
}

.product-quantity-adjust-wrapper {
  max-width: 50%;
  margin-left: pxToRem(10px);

  .product-quantity-input-group {
    border: 1px transparent solid;
  }
}

.order-total-adjust-wrapper {
  line-height: pxToRem(52px);
  margin-top: pxToRem(8px);

  input {
    text-align: right;
    font-size: pxToRem(16px) !important;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .btn-tag {
    background: #fff;
    font-weight: 500 !important;
  }

  .clear-button {
    border: 1px $color-red-500 dashed;

    i {
      color: $color-red-500 !important;
    }
  }

  .edit-button {
    border: 1px $color-grey-500 dashed;

    &:hover {
      border: 1px $color-green-600 dashed;
      padding-right: pxToRem(16px) !important;

      .btn-tag {
        margin-right: pxToRem(16px) !important;
      }

      .icon {
        color: $color-green-600;
      }
    }
  }

  .icon.wb-edit {
    font-size: 14px;
  }
}

.btn.hover-show-tag {
  padding-right: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover {
    padding-right: pxToRem(10px);

    .icon {
      width: 1em;
      margin: -1px 3px 0;
      visibility: visible;
    }

    .btn-tag {
      margin-right: pxToRem(10px);
    }
  }

  .btn-tag {
    margin-right: 0;
  }

  .icon {
    width: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
  }
}

.inventory-container {
  max-height: pxToRem(500px);
  overflow: auto;
  overflow-x: hidden;

  @media screen and (max-width: 1024px) {
    max-height: pxToRem(300px);
  }
}

// Animations
@keyframes Appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.appear {
  animation: Appear 350ms;
}
