.flex {
  display: flex;

  &.flex-column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.baseline {
    align-items: baseline;
  }

  &.align-center {
    align-items: center;
  }
}
