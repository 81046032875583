// Variables
// ----------------------------
$large-icon-form-height: 3.2rem !default;
$small-icon-form-height: 2.2rem !default;

// Text input groups
// -------------------------
.input-group-addon {
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}


.input-group-btn {
  .btn {
    padding: 6px 10px;

    > .icon {
      line-height: inherit;
      vertical-align: bottom;
    }
  }
}

// for vertical align
.input-group-lg {
  .input-group-addon,
  .input-group-btn {
    line-height: $line-height-lg;
  }
}

.input-group-sm {
  .input-group-addon,
  .input-group-btn {
    line-height: $line-height-sm;
  }
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  @extend .form-control-lg;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  @extend .form-control-sm;
}

// form icons
// ================================
.form-icons {
  .form-control-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    left: 10px;
    &+.form-control {
      border-bottom-left-radius: $input-border-radius;
      border-top-left-radius: $input-border-radius;
      padding-left: 30px;
    }
  }
  .round-input-control .form-control {
    border-radius: 200px;
  }
  .large-icon .form-control {
    height: $large-icon-form-height!important;
  }
  .small-icon .form-control {
    height: $small-icon-form-height!important;
  }
  .form-control-icon-right {
    &+.form-control {
      padding-right: 30px;
      padding-left: $input-padding-x;
    }
    left: auto;
    right: 10px;
  }
}
