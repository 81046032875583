@use '@angular/material' as mat;
.dropzone-wrapper {
  flex: 1;
  margin-left: 8px;
}

.file-drop-content {
  border: 2px dotted mat.get-color-from-palette($primary);
  width: 100%;
  border-radius: 5px;

  .content {
    height: 75px !important;
  }
}

.disabled {
  .file-drop-content {
    opacity: 0.5;

    a:hover {
      cursor: not-allowed;
    }
  }
}

.image-uploader-container {
  display: flex;

  img {
    max-height: 75px;
  }

  a {
    color: mat.get-color-from-palette($primary);

    &:hover {
      cursor: pointer;
    }
  }

  .disabled a:hover {
    cursor: not-allowed;
  }
}

.image-validation-errors {
  color: mat.get-color-from-palette($warn);
  font-size: 14px;
  margin-top: 12px;
}

cl-image-uploader.ng-invalid.ng-touched {
  .file-drop-content {
    border-color: mat.get-color-from-palette($warn);
  }

  .image-uploader-container a {
    color: mat.get-color-from-palette($warn);
  }
}
