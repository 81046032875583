cl-schedule-input {
  div.mat-form-field-flex {
    max-width: 120px;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.25em;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 8px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 4px;
  }

  button.ngx-material-timepicker-toggle {
    top: -4px;
  }

  table {
    margin: 0 auto;
    width: 100%;
  
    th {
      text-align: left;
      color: var(--text-color);
      font-weight: bold;
      white-space: nowrap;
      padding-bottom: 24px;
    }

    td:nth-child(3n), 
    td:nth-child(4n) {
      width: 125px;
    }
  }

  .cell--titlecase {
    text-transform: capitalize;
  }
}
