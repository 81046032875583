// Variables
// ========================================================================

$switch-size: 40px !default;
$switch-label-gap: 10px !default;

$switch-sm-size: 20px !default;
$switch-sm-label-gap: 8px !default;

$switch-lg-size: 60px !default;
$switch-lg-label-gap: 12px !default;

$switch-inline-gap: 20px !default;

// Checkboxes & Radios
// ========================================================================
.radio-custom,
.switch-custom {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    min-height: $line-height-computed; // Ensure the input doesn't jump when there is no text
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    cursor: pointer;
    line-height: ($switch-size/2);
  }
}

.radio-custom input[type="radio"],
.switch-custom input[type="checkbox"] {
  position: absolute;
  margin-left: -$switch-size;
  margin-top: 0;
  margin-bottom: 0;
}

.radio-custom,
.switch-custom {
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: $cursor-disabled;
    }
  }
}

.input-group-addon {
  .radio-custom,
  .switch-custom {
    margin: 0;
  }
}

// Switches
// ========================================================================
.switch-custom {
  padding-left: $switch-size;

  label {
    display: inline-block;
    position: relative;
    padding-left: $switch-label-gap;

    &:empty {
      padding-left: 0;
    }

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: $switch-size;
      height: ($switch-size/2);
      left: 0;
      margin-left: -$switch-size;
      border: 1px solid $input-border;
      border-width: ($switch-size/4);
      border-radius: ($switch-size/4);
      background-color: $color-white;

      -webkit-box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);

      transition: all 400ms ease-in-out 0s;
    }

    &::after {
      content: '';
      background-color: #fff;
      display: inline-block;
      position: absolute;
      height: ($switch-size/2);
      width: ($switch-size/2);
      border-radius: 50%;
      left: 0;
      top: 0;
      margin-left: -$switch-size;
      text-align: center;
      padding-top: 1px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
      transition: all 0.3s ease-in-out 0s;
    }

  }

  input[type="checkbox"] {
    opacity: 0;
    display: none;
    z-index: 1;
    width: $switch-size;
    height: ($switch-size/2);
    cursor: pointer;

    &:checked + label::before,
    &:checked + input[type="hidden"] + label::before {
      border-color: $input-border;
    }

    &:checked + label::after,
    &:checked + input[type="hidden"] + label::after {

      content: '';
      left: ($switch-size/2);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:focus {
      border: none;
    }

    &:disabled + label,
    &:disabled + input[type="hidden"] + label {
      opacity: 0.65;

      &::before {
        background-color: $input-bg-disabled;
        border-color: $input-border;
        border-width: 1px;
        cursor: not-allowed;
      }
    }
  }

  &.switch-circle label::before {
    border-radius: 50%;
  }

  &.horizontal {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: ($padding-base-vertical + pxToRem(1px));
    min-height: (pxToRem($line-height-computed) + $padding-base-vertical + pxToRem(1px));
  }

  &.switch-inline {
    margin-top: 0;
    display: inline-block;
    margin-bottom: 0;
  }
}

.switch-inline + .switch-inline {
  margin-left: $switch-inline-gap;
}

// Checkbox Color Modifier
// ========================================================================

@mixin switch-variant($color) {
  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:checked + input[type="hidden"] + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after {
      color: $color-white;
    }
  }
}

@mixin switch-inactive-variant($color) {
  input[type="checkbox"] + label,
  input[type="checkbox"] + input[type="hidden"] + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
  }
}

.switch-default {
  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:checked + input[type="hidden"] + label {
    &::before {
      background-color: $color-white;
      border-color: $input-border;
      border-width: 1px;
    }
    &::after {
      color: $brand-primary;
    }
  }
}

.switch-primary {
  @include switch-variant($brand-primary);
}

.switch-danger {
  @include switch-variant($brand-danger);
}

.switch-info {
  @include switch-variant($brand-info);
}

.switch-warning {
  @include switch-variant($brand-warning);
}

.switch-success {
  @include switch-variant($brand-success);
}

.switch-dark {
  @include switch-variant($brand-dark);
}

.switch-inactive-primary {
  @include switch-inactive-variant($brand-primary);
}

.switch-inactive-danger {
  @include switch-inactive-variant($brand-danger);
}

.switch-inactive-info {
  @include switch-inactive-variant($brand-info);
}

.switch-inactive-warning {
  @include switch-inactive-variant($brand-warning);
}

.switch-inactive-success {
  @include switch-inactive-variant($brand-success);
}

.switch-inactive-dark {
  @include switch-inactive-variant($brand-dark);
}

// Checkbox Size Modifier
// ========================================================================
@mixin switch-size($size, $label-gap) {
  padding-left: $size;

  label {
    padding-left: $label-gap;
    line-height: ($size/2);

    &:empty {
      padding-left: 0;
    }

    &::before {
      border-width: ($size/4);
      border-radius: ($size/4);
    }

    &::before, &::after {
      width: $size;
      height: ($size/2);
      margin-left: -$size;
    }

    &::after {
      line-height: ($size/2);
      height: ($size/2);
      width: ($size/2);
    }
  }

  input[type="checkbox"] {
    width: $size;
    height: ($size/2);

    &:checked + label::before,
    &:checked + input[type="hidden"] + label::before {
      border-width: ($size/4);
    }

    &:checked + label::after,
    &:checked + input[type="hidden"] + label::after {
      left: ($size/2);
    }
  }
}

.switch-sm {
  @include switch-size($switch-sm-size, $switch-sm-label-gap);
}

.switch-lg {
  @include switch-size($switch-lg-size, $switch-lg-label-gap);
}


