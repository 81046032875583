// $use-font-family:      "Roboto";
// $use-font-family-url:  "http://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic";
// $font-family-base:     $font-family-sans-serif;

// $font-weight-override: true;
// $font-weight-light:    300;
// $font-weight-normal:   400;
// $font-weight-medium:   500;
// $font-weight-bold:     700;

// $font-weight-base:     $font-weight-normal;
// $headings-font-weight: $font-weight-normal;

$use-font-family:      "Roboto";
$use-font-family-url:  "http://fonts.googleapis.com/css?family=Roboto:300,400,500,300italic";
$font-family-base:     $font-family-sans-serif;

$font-weight-override: true;
$font-weight-light:    100;
$font-weight-normal:   300;
$font-weight-medium:   400;
$font-weight-bold:     500;

$font-weight-base:     $font-weight-normal;
$headings-font-weight: $font-weight-medium;
